import axios from "axios";
import config from "../../public/config.json"

const ApiOmniwallet = axios.create({
    withCredentials: true,
    timeout: 30000,
    headers: {
        Authorization: `Bearer ${localStorage.getItem("omniwalletTemporaryToken")}`
    }
});

ApiOmniwallet.interceptors.request.use(function (axiosConfig) {
    const hostname = window.location.hostname;
    axiosConfig.baseURL = `https://api.${hostname}`;

    // For local testing (TODO: should check node env prod or dev instead)
    //const hostname = 'tenant.base_omniwallet.test';
    //config.baseURL = `https://${hostname}`;

    if (config.api.dev_mode === false) {
        axiosConfig.headers.Authorization = `Bearer ${localStorage.getItem("omniwalletTemporaryToken")}`;
    } else {
        axiosConfig.headers.Authorization = `Bearer ${config.api.dev_token}`;
    }

    return axiosConfig;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

export default ApiOmniwallet;
