export default {
  "General": {
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo actual"])},
    "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canjear"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página no encontrada. Por favor, vuelve al Inicio o póngase en contacto con nosotros a través de nuestro correo electrónico"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productos"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
    "panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartera"])},
    "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacciones"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espere un momento..."])},
    "problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha ocurrido un error inesperado"])},
    "mail-example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usuario", "@", "ejemplo.com"])},
    "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntos disponibles"])},
    "no-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay datos disponibles"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])}
  },
  "Login": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicia sesión"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea una cuenta"])},
    "your-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu email"])},
    "your-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu contraseña"])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
    "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de nacimiento"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
    "repeat-your-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repite tu contraseña"])},
    "login-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicia sesión"])},
    "login-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicia sesión"])},
    "have-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Tienes una cuenta?"])},
    "create-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea una cuenta"])},
    "privacy-politics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidad"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntas frecuentes"])},
    "try-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuelve a intentarlo"])},
    "legal-disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviso legal"])},
    "wrong-credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CREDENCIALES INCORRECTAS"])},
    "wrong-credentials-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico o contraseña no válidos"])},
    "wrong-credentials-register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hemos podido efectuar el registro"])},
    "starting-session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciando sesión..."])},
    "go-back-login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
    "introduce-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduce tu nombre"])},
    "introduce-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduce tu apellido"])},
    "user-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario creado con éxito"])},
    "user-not-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo sentimos, no hemos podido crear el usuario"])},
    "error-register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compruebe que las contraseñas coinciden o utilice otro correo electrónico"])},
    "email-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca un email válido"])},
    "accept-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acepto las políticas de privacidad"])},
    "accept-policy-text-part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acepto las "])},
    "accept-policy-link-part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["políticas de privacidad"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
    "please-accept-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, para continuar acepta las políticas de privacidad"])},
    "web-omniwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Quiere acceder a la página web de Omniwallet?"])},
    "forgotten-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Has olvidado la contraseña?"])},
    "web-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceder"])},
    "go-back-app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver atrás"])},
    "get-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperar contraseña"])},
    "introduce-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduce tu correo actual de"])},
    "send-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te enviaremos un email para recuperar tu contraseña"])},
    "current-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña actual"])},
    "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar contraseña"])},
    "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva contraseña"])},
    "confirm-new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación de nueva contraseña"])},
    "success-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu contraseña ha sido cambiada con éxito"])},
    "problem-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asegúrese que su correo electrónico es correcto y que sus contraseñas coinciden."])},
    "problem-password-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asegúrese que su correo electrónico es correcto."])},
    "get-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["para obtener un link de recuperación"])},
    "apply-for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar"])},
    "check-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulte el correo electrónico para recuperar su contraseña"])},
    "SignUp": {
      "your-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu nombre"])},
      "your-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu apellido"])},
      "your-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu teléfono"])},
      "min-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige una contraseña más segura. Use una combinación de letras y números"])},
      "min-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su teléfono debe contener al menos 9 caracteres"])},
      "wrong-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha incorrecta"])}
    }
  },
  "Profile": {
    "my-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi perfil"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
    "success-edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información editada con éxito"])},
    "fail-edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo sentimos, no hemos podido editar su información"])},
    "go-back-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuelve al inicio"])},
    "edit-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuelve a editar"])},
    "personal-address": {
      "my-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis direcciones"])},
      "shipping-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direcciones de envio"])},
      "billing-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direcciones de facturación"])},
      "add-shipping-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir nueva dirección de envio"])},
      "add-billing-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir nueva dirección de facturación"])},
      "please-introduce-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca una dirección"])},
      "please-introduce-postalcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca su Código Postal"])},
      "please-introduce-city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca su ciudad"])},
      "please-introduce-province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca su provincia"])},
      "please-introduce-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca su país"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
      "postalcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código Postal"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciudad"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provincia"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios"])},
      "confirm-delete-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Quieres borrar esta dirección?"])}
    },
    "personal-data": {
      "my-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos personales"])},
      "please-introduce-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca su email"])},
      "please-introduce-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca su nombre"])},
      "please-introduce-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca su apellido"])},
      "please-introduce-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca su teléfono"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])}
    },
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacidad"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])},
    "logout-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrando sesión..."])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacta"])},
    "are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Está seguro?"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustes"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idiomas"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, espere un momento..."])}
  },
  "Home": {
    "catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catálogo"])},
    "see-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todos"])},
    "last-movements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimos movimientos"])},
    "see-movements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver movimientos"])}
  },
  "ControlPanel": {
    "control-panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel de control"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartera"])},
    "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi tarjeta"])},
    "hello-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hola"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido al panel de control de tu cuenta"])},
    "welcome2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde aquí puedes ver tu saldo disponible, tu dirección predeterminada y el código con el número de tarjeta de tu cuenta de fidelización."])},
    "card-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº de tarjeta"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
    "download-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarga tu tarjeta"])},
    "info-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya puedes comenzar a acumular y canjear puntos. También serás el primero en enterarse de las ventas, ofertas especiales y eventos exclusivos"])}
  },
  "BuyingProcess": {
    "transactions": {
      "past-transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacciones pasadas"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
      "expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastos"])},
      "incomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresos"])},
      "transaction-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº de pedido"])},
      "transaction-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de pedido"])},
      "transaction-state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado del pedido"])},
      "transaction-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información del pedido"])},
      "transaction-exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedido canjeado"])},
      "transaction-reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº referencia"])},
      "manage-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionar mi pedido"])},
      "continue-buying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguir comprando"])},
      "processing-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesando su pedido"])},
      "no-processing-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo sentimos, no podemos canjear su pedido"])},
      "no-address-chosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ninguna dirección seleccionada"])},
      "add-new-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir nueva dirección"])},
      "use-same-address-invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizar la misma dirección para la facturación"])},
      "add-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir comentario"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
      "go-back-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuelve al Inicio"])}
    },
    "products": {
      "availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibilidad"])},
      "lack-of-points-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te faltan"])},
      "lack-of-points-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["puntos para canjear este producto."])},
      "want-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo quiero"])},
      "stock1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay stock"])},
      "stock2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimas unidades"])},
      "stock3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En stock"])},
      "available-points-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tus"])},
      "available-points-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disponibles"])},
      "buy-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación de compra"])},
      "total-expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coste total"])},
      "final-balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo final"])},
      "home-delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envío a domicilio"])},
      "pickup-store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recogida en tienda"])},
      "extra-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La opción de envío a domicilio tiene un coste extra de"])},
      "please-choose-option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, escoja una opción"])},
      "please-choose-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, especifique una dirección"])},
      "send-to-my-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiero enviar mi producto a la dirección de mi perfil"])},
      "new-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva dirección de envío"])},
      "my-transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su pedido"])},
      "exchanged-successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ha sido canjeado satisfactoriamente."])},
      "will-get-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibirá un correo electrónico con los datos del pedido. ¡Le esperamos!"])},
      "no-stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay stock"])},
      "no-shops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay tiendas"])},
      "no-addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay ninguna dirección"])},
      "last-units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimas unidades"])},
      "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En stock"])},
      "see-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todos los productos"])},
      "no-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aun no tenemos productos disponibles"])},
      "no-points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No dispone de saldo suficiente"])},
      "search-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busca un producto..."])},
      "show-stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar solo con stock"])},
      "available-points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar solo con puntos disponibles"])},
      "show-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar todos"])},
      "choose-option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escoge una opción"])},
      "filter-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
      "filter-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
      "filter-stock-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con stock disponible"])},
      "filter-enough-points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con puntos suficientes"])},
      "sort-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orden"])},
      "sort-a-z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alfabético: A-z"])},
      "sort-z-a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alfabético: Z-a"])},
      "sort-cheaper-first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio: Menor a mayor"])},
      "sort-expensive-first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio: Mayor a menor"])}
    }
  },
  "FAQ": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntas frecuentes (FAQ)"])},
    "title-about-app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntas sobre el catálogo"])},
    "title-about-login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio de sesión y contraseña"])},
    "title-about-buying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compras"])},
    "app-question-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Dónde puedo descargar el catálogo?"])},
    "app-question-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cuánto cuesta el catálogo?"])},
    "app-question-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué viene incluido en el catálogo de Omniwallet?"])},
    "app-question-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Soy incapaz de cargar el catálogo o los productos que hay dentro. ¿Qué ha pasado?"])},
    "app-question-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué móviles pueden utilizar el catálogo?"])},
    "app-answer-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El catálogo Omniwallet puede descargarse desde el navegador del móvil. Hay móviles en los que el aviso de descarga aparece en el mismo navegador, otros que puede encontrarse en configuración."])},
    "app-answer-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El catálogo Omniwallet es totalmente gratuita."])},
    "app-answer-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El catálogo ofrece varias opciones al cliente. Permite canjear productos por puntos, consultar transacciones pasadas y hacer cambios en el perfil."])},
    "app-answer-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primero de todo, por favor, compruebe la conexión a Internet. En caso que siga dando errores, cierre sesión y vuelva a autenticarse de nuevo."])},
    "app-answer-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El catálogo está actualmente disponible en iOS y Android."])},
    "login-question-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo inicio sesión?"])},
    "login-question-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo cierro sesión?"])},
    "login-question-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["He perdido mi usuario y contraseña"])},
    "login-question-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puedo descargar la información. ¿Qué ha pasado?"])},
    "login-answer-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca su correo electrónico y su contraseña. Su contraseña es el número de tarjeta por defecto asignada en el correo electrónico. En el caso que se haya registrado a través de el catálogo, la contraseña es la indicada en el formulario."])},
    "login-answer-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, diríjase al perfil y en “privacidad”, haga click a “Cerrar sesión”."])},
    "login-answer-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, póngase en contacto con omniwallet", "@", "omniwallet.net."])},
    "login-answer-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para acceder a el catálogo debes usar el mismo correo electrónico y contraseña asociado a su cuenta. En caso de problemas, por favor, cierre sesión. Si el problema no se soluciona, póngase en contacto con atención al cliente omniwallet", "@", "omniwallet.net."])},
    "buying-question-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo puedo comprar un producto?"])},
    "buying-question-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo puedo ver información de una transacción pasada?"])},
    "buying-question-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo edito mi perfil?"])},
    "buying-question-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo consulto mi número de tarjeta?"])},
    "buying-answer-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sigue los siguientes pasos: Diríjase a la página principal o a la página de productos. Después, haga click en un producto. Si está disponible y tiene puntos suficientes, haga click a canjear. Seguidamente, especifique el destino del producto: recogida en tienda o envío a domicilio. Si escoge la opción envío a domicilio puede añadir una nueva dirección. Finalmente, haga click en comprar y proceda con la transacción."])},
    "buying-answer-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, diríjase a la página transacciones y haga click en cualquiera. Puede consultar tanto gastos como ingresos."])},
    "buying-answer-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, diríjase a la página de perfil y haga click en el lápiz."])},
    "buying-answer-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, diríjase al panel de control y allí podrá ver su número de tarjeta."])}
  }
}