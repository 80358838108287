export default {
  mounted() {
    if (sessionStorage.getItem("locale")) {
      this.$i18n.locale = sessionStorage.getItem("locale");
    } else {
      sessionStorage.setItem("locale", this.$i18n.locale);
    }

    if (this.$i18n.locale === "es") {
      this.settings.localization.locales[0];
    }
    if (this.$i18n.locale === "en") {
      this.settings.localization.locales[1];
    }
  },
};
