import Api from "../Api";

export default {
  async allSettings() {
    try {
      const fetchSettings = await Api.get("api/frontend/settings");
      return fetchSettings;
    } catch (err) {
      console.log(err);
    }
  },
};
