import Api from "../Api";

export default {
  async allAddresses() {
    try {
      const fetchAdresses = await Api.get("front-catalog/customers/addresses");
      //console.log("Addresses: ", fetchAdresses);
      return fetchAdresses;
    } catch (err) {
      console.log(err);
    }
  },
};
